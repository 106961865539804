<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <router-link :to="{ name: 'Main' }" class="simple-text logo-normal">
          <span style="font-weight: bold;font-size: 18px;margin-left: 35px">CABO.PARTY</span>

        </router-link>
      </div>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
            >
              <hr />
            </sidebar-item>
          </sidebar-item>
        </slot>
        <slot></slot>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  data: () => {
    return {
      miniLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png',
    }
  },
  props: {
    title: {
      type: String,
      default: 'Cabo Party',
      description: 'Sidebar title',
    },
    shortTitle: {
      type: String,
      default: 'TP',
      description: 'Sidebar short title',
    },
    logo: {
      type: String,
      default: 'https://imgix.cosmicjs.com/57a1ab60-a461-11ee-b417-db331415685f-tpicon.png',
      description: 'Sidebar logo',
    },
    backgroundColor: {
      type: String,
      default: 'vue',

      validator: (value) => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
