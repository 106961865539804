import MainLayout from '../pages/Layout/MainLayout.vue';

const HomePage = () => import('../pages/Home/Home.vue');
const Club = () => import('@/pages/Venues/Club');
const BeachClubs = () => import('@/pages/Venues/BeachClubs');
const Clubs = () => import('@/pages/Venues/Clubs');
const PartyCalendar = () => import('@/pages/PartyCalendar/PartyCalendar');
const Event = () => import('@/pages/Events/Event');
const About = () => import('@/pages/About/About');
const Faq = () => import('@/pages/Faq/Faq');
const Article = () => import('@/pages/Article/Article');
const Blog = () => import('@/pages/Article/Blog');
const Redirector = () => import('@/pages/Redirector/Redirector');
const CaboShorts = () => import('@/pages/CaboShorts/CaboShorts');
const Dinners  = () => import('@/pages/Dinners/Dinners');
const Reservations = () => import('@/pages/Reservations/ReservationsPage');
const CaboNightLife = () => import('@/pages/CaboNightLife/CaboNightLife');
// const ClubReservations = () => import('@/pages/Venues/ClubReservations');
const CaboNye2025 = () => import('@/pages/NYE/CaboNye2025');
const ConciergeServices = () => import('@/pages/ConciergeServices/ConciergeServices')
import redirects from "@/pages/redirects";
// GeneralViews
// import NotFound from 'template_reference/GeneralViews/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    component: MainLayout,
    name: 'Main',
    redirect: {
      name: 'Home',
    },
    children: [
      // Redirects
      ...redirects,
      {
        path: '/',
        name: 'Home',
        component: HomePage,
      },
      {
        path: '/event/:slug',
        name: 'Event',
        component: Event,
        props: (route) => ({slug: route.params.slug}),
      },
      {
        path: '/about',
        name: 'About',
        components: {default: About},
      },
      {
        path: '/los-cabos-mexico-event-calendar',
        name: 'PartyCalendar',
        // meta: { hideFooter: true},
        component: PartyCalendar,
        props: (route) => ({slug: route.params.slug}),
      },
      {
        path: '/calendar',
        redirect: () => {
          return '/los-cabos-mexico-event-calendar';
        },
      },
      {
        path: '/los-cabos-clubs',
        name: 'Clubs',
        component: Clubs,
      },
      {
        path: '/los-cabos-clubs/:slug',
        name: 'Club',
        component: Club,
        props: (route) => ({slug: route.params.slug}),
      },
      // {
      //   path: '/los-cabos-clubs/:slug/reservations',
      //   name: 'ClubReservations',
      //   component: ClubReservations,
      //   props: (route) => ({slug: route.params.slug}),
      // },
      {
        path: '/los-cabos-beach-clubs',
        name: 'BeachClubs',
        component: BeachClubs,
      },
      {
        path: '/blog',
        name: 'Blog',
        component: Blog,
      },
      {
        path: '/los-cabos-nightlife',
        name: 'CaboNightLife',
        component: CaboNightLife,
      },
      {
        path: '/article/:slug',
        name: 'Article',
        component: Article,
        props: (route) => ({slug: route.params.slug}),
      },
      {
        path: '/faq',
        name: 'FaqShort',
        redirect: () => {
          return '/los-cabos-traveler-frequent-questions';
        },
      },
      {
        path: '/los-cabos-traveler-frequent-questions',
        name: 'Faq',
        component: Faq,
      },
      {
        path: '/los-cabos-dinner-table-reservations',
        name: 'Dinners',
        component: Dinners,
      },
      {
        path: '/reservations',
        name: 'Reservations',
        component: Reservations,
      },
      {
        path: '/los-cabos-shorts/:slug',
        name: 'CaboShort',
        component: CaboShorts,
        props: (route) => ({ slug: route.params.slug }),
      },
      {
        path: '/l',
        name: 'Redirector',
        component: Redirector,
        props: (route) => ({slug: route.query.u}),
      },
      {
        path: '/los-cabos-calendar',
        name: 'GoogleCalendar',
        beforeEnter() {
          window.location.href = 'https://calendar.google.com/calendar/u/0/embed?height=600&wkst=1&ctz=America/Mazatlan&bgcolor=%234285F4&showNav=0&showPrint=0&mode=AGENDA&src=Y19mNWU3NjQ5YTliN2U1NTg2Mzg2MmM2MTM3NGYyMzQ1NGVjOTk0ZDJjM2E1NzkxY2E2ZDI5MDZmYjg3YjI0ZWVhQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23E67C73';
        },
      },
      {
        path: '/los-cabos-party-new-year-celebration-2025',
        name: 'LosCabosNye2025',
        component: CaboNye2025
      },
      {
        path: '/los-cabos-concierge-services',
        name: 'ConciergeServices',
        component: ConciergeServices,
      }
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

export default routes;
