var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"tp","title":"cabo.party"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Home',
          icon: 'tim-icons icon-molecule-40',
          path: '/',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Clubs',
          icon: 'tim-icons icon-planet',
          path: '/los-cabos-clubs',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Calendar',
          icon: 'tim-icons icon-calendar-60',
          path: '/los-cabos-mexico-event-calendar',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Dinner',
          icon: 'tim-icons icon-bag-16',
          path: '/los-cabos-dinner-table-reservations',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'NightLife',
          icon: 'tim-icons icon-istanbul',
          path: '/los-cabos-nightlife',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'NYE Celebration',
         icon: 'tim-icons icon-spaceship',
          path: '/los-cabos-party-new-year-celebration-2025',
        }}})],1)],2),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('Transition',{attrs:{"name":"fade","appear":""}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }