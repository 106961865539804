export default Object.freeze([
    // Lazarus
    {
      path: '/article/article-the-nightlife-guide-of-los-cabos',
      name: 'old-nightlife',
      redirect: () => {
        return '/los-cabos-nightlife';
      }
    },

  ]
)


