import Vue from 'vue';
import DashboardPlugin from './plugins/app-plugin';
import App from './App.vue';
import store from './store/index.js';
// router setup
import router from './routes/router';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import Rollbar from 'vue-rollbar';
import VueGtag from 'vue-gtag';


// lazy loading
import 'floating-vue/dist/style.css';
import {
  // Directives
  // VTooltip,
  // VClosePopper,
  // // Components
  // Dropdown,
  Tooltip,
  Menu,
} from 'floating-vue';
// Vue.use(FloatingVue)

Vue.use(DashboardPlugin);
Vue.use(VueLazyload);
// import infiniteScroll from 'vue-infinite-scroll';
// Vue.use(infiniteScroll);
// const sweet_alert_options = {
//   confirmButtonColor: 'aquamarine',
//   cancelButtonColor: '#ff7674',
//   background: 'black',
// };
//


Vue.use(
  VueGtag,
  {
    appName: 'cabo.party',
    pageTrackerScreenviewEnabled: true,
    enabled: process.env.NODE_ENV === 'production',
    config: {
      id: 'G-NSNNBE74TJ',
      enabled: process.env.NODE_ENV === 'production',
    },
  },
  router
);

Vue.use(Rollbar, {
  accessToken:
    process.env.NODE_ENV === 'production'
      ? 'c10b07ed70864b638adac81a048fb427'
      : '',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
  },
});

Vue.use(VueMeta, {
  debounceWait: 7,
});

// Vue.component('VDropdown', Dropdown)
Vue.component('VTooltip', Tooltip);
Vue.component('VMenu', Menu);
/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
});
Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};
