<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {
  methods: {
    initializeLayout() {
      if (!this.$rtl.isRTL) {
        // Just make sure rtl css is off when we are not on rtl
        this.$rtl.disableRTL();
      }
    },
    reload() {
      window.location.reload();
    },
  },
  mounted() {
    let twoHour = 3600 * 1000 * 2;
    this.initializeLayout();
    setTimeout(this.reload, twoHour);
  },
};
</script>
