export const COSMIC_BASE_URL =
  'https://api.cosmicjs.com/v3/buckets/tulumparty-cabo/objects?read_key=YDn0yNZsHaT54IO4Uk6q4uVeiWxu0yzonz0lUo1eK91g4W8j0F';
export const MEDIA_BASE_URL =
  'https://api.cosmicjs.com/v3/buckets/tulumparty-cabo/media';

const LIMIT = 16;
export const PARTY_LIMIT = 25;
const FAQ_LIMIT = 40;
const FAQ_CLUB_LIMIT = 3;
const ALL_PARTY_LIMIT = 55;
const DJ_SCHEDULE = 100;
let metadataFieldsParty = [
  'metadata.extra_content',
  'metadata.caption',
  'metadata.video.content',
  'metadata.video.metadata',
  'metadata.date',
  // 'metadata.brand.title',
  // 'metadata.brand.slug',
  'metadata.start_time',
  'metadata.tickets_link',
  'metadata.reservations_link',
  'metadata.event_link',
  'metadata.main_image',
  'metadata.club.title',
  'metadata.club.metadata.address',
  'metadata.club.slug',
].join(',');
let metadataFieldsParties = [
  'metadata.extra_content',
  'metadata.caption',
  'metadata.video.content',
  'metadata.video.metadata',
  'metadata.date',
  // 'metadata.brand.title',
  // 'metadata.brand.slug',
  'metadata.tickets_link',
  'metadata.reservations_link',
  'metadata.lineup',
  'metadata.start_time',
  'metadata.main_image',
  // 'metadata.venue.title',
  // 'metadata.venue.metadata.address',
  // 'metadata.venue.slug'
].join(',');
let metadataFieldsPartiesIndex = [
  'metadata.extra_content',
  'metadata.caption',
  // 'metadata.video.content',
  // 'metadata.video.metadata',
  'metadata.date',
  // 'metadata.brand.title',
  // 'metadata.brand.slug',
  'metadata.tickets_link',
  'metadata.reservations_link',
  'metadata.lineup',
  'metadata.start_time',
  'metadata.main_image',
  // 'metadata.venue.title',
  // 'metadata.venue.metadata.address',
  // 'metadata.venue.slug'
].join(',');
let metadataFieldsAllParty = [
  'metadata.caption',
  'metadata.main_image',
  'metadata.date',
  'metadata.lineup',
  'metadata.start_time',
].join(',');
let metadataFieldsArtist = [
  'metadata.genere',
  'metadata.headline',
  'metadata.spotify_listeners',
  'metadata.spotify_id',
  'metadata.videoid',
  'metadata.videotitle',
  'metadata.videocaption',
  'metadata.main_image',
  'metadata.soundcloud',
  'metadata.short',
  'metadata.flag',
  'metadata.parties.slug',
  'metadata.parties.title',
  'metadata.parties.metadata.main_image',
  'metadata.parties.metadata.start_time',
  'metadata.parties.metadata.venue.slug',
  'metadata.parties.metadata.venue.title',
  'metadata.parties.metadata.date',
  'metadata.instagram',
  'metadata.headline',
].join(',');
let metadataFieldsFestival = [
  'metadata.parties.slug',
  'metadata.parties.title',
  // 'metadata.parties.metadata.brand.title',
  // 'metadata.parties.metadata.brand.slug',
  'metadata.parties.metadata.main_image',
  'metadata.parties.metadata.lineup',
  'metadata.parties.metadata.start_time',
  // 'metadata.parties.metadata.venue.slug',
  // 'metadata.parties.metadata.venue.title',
  'metadata.parties.metadata.date',
].join(',');
let metadataFieldsBrand = [
  'metadata.main_image',
  'metadata.logo',
  'metadata.instagram_profile',
  'metadata.past_parties.slug',
  'metadata.past_parties.title',
  'metadata.past_parties.metadata.main_image',
  'metadata.past_parties.metadata.lineup',
  'metadata.past_parties.metadata.start_time',
  'metadata.past_parties.metadata.venue.slug',
  'metadata.past_parties.metadata.venue.title',
  'metadata.past_parties.metadata.date',
].join(',');
let metadataFieldsBrandIndex = [
  'metadata.main_image',
  'metadata.description',
  'metadata.logo',
].join(',');

// One day before to prevent timezone nuisances
// This code depends on a check on filterParties/incomingParties
let ONE_DAY = 86400000;
let PARTY_START_RANGE = new Date(Date.now() - ONE_DAY)
  .toISOString()
  .slice(0, 10);

const getParty = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,content,${metadataFieldsParty}&query={"type":"san-jose-cabo-party","slug":"${slug}"}`
    )
  ).then((response) => response.json());
};

function getMiniParties(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${ALL_PARTY_LIMIT}&sort=metadata.date&props=title,slug,metadata&query={
                    "type":"miniparties",
                    "metadata.date": {"$gte": "${PARTY_START_RANGE}"}

      }`
    )
  ).then((response) => response.json());
}

function getParties(pagination) {
  //2024-12-04
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsPartiesIndex}&query={
              "type":"san-jose-cabo-party",
              "metadata.show_in_main_page":true,
              "metadata.date": {"$gte": "${PARTY_START_RANGE}"}
       }`
    )
  ).then((response) => response.json());
}

function getClubParties(club) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&sort=metadata.date&limit=${PARTY_LIMIT}&props=title,slug,content,${metadataFieldsParties}&query={
      "type":"san-jose-cabo-party",
      "slug": { "$regex": "${club}", "$options": "i"},
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"}
     }`
    )
  ).then((response) => response.json());
}

function getAllParties(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${ALL_PARTY_LIMIT}&props=title,slug,content,${metadataFieldsAllParty}&sort=metadata.date&query={
      "type":"san-jose-cabo-party",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"}
      }`
    )
  ).then((response) => response.json());
}

function getDjSchedule() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${DJ_SCHEDULE}&props=title,slug,content,metadata.image_url,metadata.link,metadata.date,metadata.start_time,metadata.artist.metadata.short,metadata.artist.metadata.spotify_id,metadata.artist.metadata.videoid,metadata.artist.metadata.videotitle,metadata.artist.slug,metadata.artist.metadata.flag,metadata.artist.metadata.soundcloud,metadata.artist.metadata.main_image.imgix_url&query={
      "type":"autodjschedules"}`
    )
  ).then((response) => response.json());
}

function getBeachClubs(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata&query={"type":"beachclubs"}`
    )
  ).then((response) => response.json());
}
function getVenues(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata&query={"type":"clubs"}`
    )
  ).then((response) => response.json());
}

function getVenue(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${LIMIT}&props=title,slug,metadata&query={"type":"clubs", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getArtists(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug&query={"type":"artists"}`
    )
  ).then((response) => response.json());
}

function getArtistVideo(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=1&props=title,slug,metadata.videoid,metadata.videocaption,metadata.videotitle&query={"type":"artists","slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getArtist(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&depth=1&props=title,slug,content,${metadataFieldsArtist}&query={"type":"artists","slug":"${slug}" }`
    )
  ).then((response) => response.json());
}

function getArticles(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata.media,metadata.caption,metadata.summary&query={"type":"articles"}`
    )
  ).then((response) => response.json());
}

function getArticle(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,metadata.content,published_at,created_at,metadata.media,metadata.caption,metadata.summary&query={"type":"articles",  "slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getFaqs() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${FAQ_LIMIT}&props=title,slug,content,metadata&query={"type":"faqs"}`
    )
  ).then((response) => response.json());
}

function getFaqsByCategory(category) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${FAQ_CLUB_LIMIT}&props=title,slug,content,metadata&query={"type":"faqs", "metadata.category": "${category}"}`
    )
  ).then((response) => response.json());
}

function getDynamicContent() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=1&props=title,slug,metadata.content,metadata.image,metadata.link&query={"type":"dynamic-contents", "slug" :"home-dynamic-content"}`
    )
  ).then((response) => response.json());
}


const getLinks = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&depth=1&props=id,slug,title,metadata&query={"type":"links","slug": { "$regex": "${slug}"}}`
    )
  ).then((response) => response.json());
};

const getFestival = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=id,slug,title,content,${metadataFieldsFestival}&query={"type":"festivals", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
};

export default {
  getParty,
  getParties,
  getMiniParties,
  getClubParties,
  getArtistVideo,
  getAllParties,
  getDjSchedule,
  getVenues,
  getBeachClubs,
  getVenue,
  getArtists,
  getArtist,
  getFaqs,
  getArticle,
  getArticles,
  getFaqsByCategory,
  getFestival,
  getDynamicContent,
  getLinks,
  PARTY_LIMIT,
};
