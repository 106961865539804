import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;


// BEFORE
// dist/js/chunk-vendors.e6f4ea6f.js      790.86 KiB                                                                                      245.75 KiB
// dist/js/app.fdd47e88.js                209.91 KiB                                                                                      51.16 KiB
// dist/css/app.77d46ca8.css              376.57 KiB                                                                                      54.00 KiB
// dist/css/chunk-vendors.5b50cdf6.css    31.94 KiB                                                                                       4.84 KiB

