<template>
  <footer
    style="margin: 0 0; background-color: #0e0e0e !important"
    class="footer"
  >
    <!--          style="padding-left: 35%;align-content: center;">-->
    <CookieLaw buttonClass="btn btn-sm btn-primary" theme="black"></CookieLaw>
    <div
      class="row"
      style="
        border-top: 1px solid #217483;
        padding: 20px;
        background-color: #1c1c1c;
        opacity: 100 !important;
      "
    >
      <div class="col-sm-4 col-md-4">
        <!--        <ul class="nav navbar" style=" z-index: 10">-->
        <ul style="z-index: 10">
          <h5 class="tulum-party-subtitle">CABO</h5>
          <li class="nav-item">
            <router-link :to="{ path: '/#event-line' }">
              Event Line
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-dinner-table-reservations' }">
              Dinner/Show
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-mexico-event-calendar' }">
              Calendar
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-party-new-year-celebration-2025' }">
              New Year Celebration
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Clubs' }">Clubs</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Reservations' }">Reservations</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ name: 'BeachClubs' }">Beachclubs</router-link>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <router-link :to="{ name: 'PartyCalendar' }"-->
<!--              >Party Calendar</router-link-->
<!--            >-->
<!--          </li>-->
          <li class="nav-item">
            <router-link :to="{ name: 'ConciergeServices' }">Concierge Services</router-link>
          </li>
        </ul>
      </div>
      <div class="col-sm-3 col-md-3">
        <ul>
          <h5 class="tulum-party-subtitle">TOP CLUBS</h5>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/bagatelle-los-cabos' }"
              >Bagatelle</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/chambao-los-cabos' }"
            >Chambao</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/craft-los-cabos' }"
            >Craft</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/funky-geisha-los-cabos' }"
            >     Funky Geisha</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/crania-los-cabos' }"
            >Crania</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/rosa-negra-los-cabos' }"
            >Rosa Negra</router-link
            >
          </li>

          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/taboo-los-cabos' }"
            >Taboo</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/tamara-beach-club-los-cabos' }"
            >Tamara</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/los-cabos-clubs/mamazzita-los-cabos' }"
            >Mamazzita</router-link
            >
          </li>

        </ul>

      </div>

      <div class="col-sm-4 col-md-4">
        <ul>
          <h5 class="tulum-party-subtitle">RESOURCES</h5>

          <li class="nav-item">
            <a href="https://www.instagram.com/cabo.party">Instagram</a>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Faq' }"> FAQS</router-link>
          </li>
          <li class="nav-item">
            <a
              target="_blank"
              rel="noopener"
              href="https://imgix.cosmicjs.com/b71976d0-e281-11eb-b4d6-4f771ba4265e-TulumPartyAviso-Privacidad.pdf"
            >
              Aviso Privacidad
            </a>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <router-link :to="{ name: 'About' }"> About Us</router-link>-->
          <!--          </li>-->
        </ul>
      </div>
      <div class="col-sm-4 col-md-4">
        <ul>
          <h5 class="tulum-party-subtitle">OTHER LOCATIONS</h5>
          <li class="nav-item">
            <a href="https://www.tulum.party">TULUM</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-12" style="background-color: #1c1c1c">
        <a
          class="btn btn-primary btn-link"
          style="
            font-size: 12px;
            color: whitesmoke !important;
            text-transform: none;
          "
          href="https://www.instagram.com/cabo.party"
        >
          <img height="17px" width="17px" v-lazy="this.insta" />
          cabo.party
        </a>
      </div>
      <br />
      <br />
    </div>
  </footer>
</template>
<script>
import CookieLaw from 'vue-cookie-law';

export default {
  components: { CookieLaw },
  data: () => {
    return {
      insta:
        'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png?h=17&w=17&auto=format',
    };
  },
};
</script>
